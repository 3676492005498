<template>
<div class="container">
  <div class="top-box--list">
    <img class="background" src="/img/main/background.png" alt="">
    <div class="title1">托育机构质量评估标准（总分值: {{ coreData.score_all*1 }}分）</div>
    <p class="title2" >{{ coreData.org_name }}</p>
    <template v-if="role == 4 && coreData.inspect.type == 1">
      <template v-if="coreData.inspect.submit_status == 0">
        <div class="title2" style="margin-top: 15px">创建时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" v-if="coreData.inspect">自评用时：{{ coreData.inspect.total_time || '0时0分0秒' }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span></div>
      </template>
      <template v-if="coreData.inspect.submit_status == 1 && coreData.inspect.examine_status != 1">
        <div class="title2" style="margin-top: 15px">提交时间：{{ coreData.inspect.submit_date }}</div>
        <div class="title2" v-if="coreData.inspect">自评用时：{{ coreData.inspect.total_time || '0时0分0秒' }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span></div>
      </template>
      <template v-if="coreData.inspect.submit_status == 1 &&coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">提交时间：{{ coreData.inspect.submit_date }}</div>
        <div class="title2" v-if="coreData.inspect">自评用时：{{ coreData.inspect.total_time || '0时0分0秒' }}</div>
        <div class="title2" tyle="display:flex;align-items: center;padding-right: 20px">
          <span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span>
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span>
        </div>
      
        
      </template>
    </template>
    <template v-if="role == 4 && coreData.inspect.type != 1">
      <template v-if="coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="margin-top: 10px">评审提交时间：{{ coreData.inspect.examine_date }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
    </template>
    <template v-if="(role == 3 || role == 6) && coreData.inspect.type == 2">
      <template v-if="coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="margin-top: 10px">评审提交时间：{{ coreData.inspect.examine_date }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
      <template v-if="coreData.inspect.examine_status == 2">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
    </template>
    <template v-if="role == 6 && coreData.inspect.type == 4">
      <template v-if="coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="margin-top: 10px">评审提交时间：{{ coreData.inspect.examine_date }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
      <template v-if="coreData.inspect.examine_status == 2">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px"><span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
    </template>
    <template v-if="role == 2 && coreData.inspect.type == 2">
      <template v-if="coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="margin-top: 10px">评审提交时间：{{ coreData.inspect.examine_date }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
      <template v-if="coreData.inspect.examine_status == 2">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
    </template>
    <template v-if="role == 2 && (coreData.inspect.type == 3 || coreData.inspect.type == 4)">
      <template v-if="coreData.inspect.examine_status == 1">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="margin-top: 10px">评审提交时间：{{ coreData.inspect.examine_date }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
      <template v-if="coreData.inspect.examine_status == 2">
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.score*1 }}分</span></span></div>
      </template>
    </template>
    <template v-if="(role == 2 || role == 6 || role == 3) && coreData.inspect.type == 1">
      <template v-if="coreData.inspect.examine_status == 0">
        <div class="title2" style="margin-top: 15px">提交时间：{{ coreData.inspect.submit_date }}</div>
        <div class="title2" v-if="coreData.inspect">自评用时：{{ coreData.inspect.total_time || '0时0分0秒' }}</div>
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">
          <span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span>
        </div>
      </template>
      <!--<template v-if="coreData.inspect.examine_status == 1">

<div class="title2" style="margin-top: 15px">提交时间：{{ coreData.inspect.submit_date }}</div>
	    <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.created_time }}</div>
	    <div class="title2" style="display:flex;align-items: center;padding-right: 20px">

<span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span>

<span>评审总分：<span class="title2-score">{{ coreData.inspect.examine_score*1 }}分</span></span>

</div>
	  </template>-->
      <template v-if="coreData.inspect.examine_status >0">
        <div class="title2" style="margin-top: 15px">提交时间：{{ coreData.inspect.submit_date }}</div>
        <div class="title2" style="margin-top: 15px">评审开始时间：{{ coreData.inspect.examine_start_time }}</div>
        <!--<div class="title2" v-if="coreData.inspect">自评用时：{{ coreData.inspect.total_time || '0时0分0秒' }}</div>-->
        <div class="title2" style="display:flex;align-items: center;padding-right: 20px">

          <span style="margin-right: 60px;">自评总分：<span class="title2-score">{{ coreData.inspect.score * 1 + '分' }}</span></span>
          <span>评审总分：<span class="title2-score">{{ coreData.inspect.examine_score*1 }}分</span></span></div>
      </template>
    </template>
  </div>
  <div class="content-box--list">
    <div class="single-box__outer">
      <div class="single-box" v-for="(item, index) in coreData.list" :key="index" @click="toDetail(item.object_pid)">
        <div class="title">
          <img :src="item.icon" alt="">
          <div style="margin-left: 6px">{{ item.name }} </div><van-icon name="arrow" />
        </div>
        <template v-if="(role == 2 || role == 6|| role == 3) && coreData.inspect.type == 1&&coreData.inspect.examine_status==0">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }} </div>
          <div class="content" v-else>分值: {{ item.score_all }} </div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }} </div>
          <div class="content" v-else>得分: {{ item.score }} </div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }} </div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }} </div>
          <div class="content">评估进度: {{ item.total_ratio }} </div>
        </template>
        <template v-if="(role == 2 || role == 6|| role == 3) && coreData.inspect.type == 1&&coreData.inspect.examine_status>0">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }} / <span class="content-supv">{{ item.count }}</span></div>
          <div class="content" v-else>分值: {{ item.score_all }} / <span class="content-supv">{{ item.score_all }}</span></div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }} / <span class="content-supv">{{ item.examine_score }}</span></div>
          <div class="content" v-else>得分: {{ item.score }} / <span class="content-supv">{{ item.examine_score }}</span></div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }} / <span class="content-supv">{{ item.examine_score_ratio }}</span></div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }} / <span class="content-supv">{{ item.examine_score_ratio }}</span></div>
          <div class="content">评估进度: {{ item.total_ratio }} / <span class="content-supv">{{ item.examine_total_ratio }}</span></div>
        </template>
        <template v-if="(role == 2 || role == 6) && coreData.inspect.type == 2">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }}</div>
          <div class="content" v-else>分值: {{ item.score_all }}</div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }}</div>
          <div class="content" v-else>得分: {{ item.score }}</div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }}</div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }}</div>
          <div class="content">评估进度: {{ item.total_ratio }}</div>
        </template>
        <template v-if="(role == 2 || role == 6) && (coreData.inspect.type == 3 || coreData.inspect.type == 4)">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }}</div>
          <div class="content" v-else>分值: {{ item.score_all }}</div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }}</div>
          <div class="content" v-else>得分: {{ item.score }}</div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }}</div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }}</div>
          <div class="content">评估进度: {{ item.total_ratio }}</div>
        </template>
        <template v-if="role == 3 && coreData.inspect.type == 2">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }}</div>
          <div class="content" v-else>分值: {{ item.score_all }}</div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }}</div>
          <div class="content" v-else>得分: {{ item.score }}</div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }}</div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }}</div>
          <div class="content">评估进度: {{ item.total_ratio }}</div>
        </template>
        <template v-if="role == 4 && coreData.inspect.type == 1 && coreData.inspect.examine_status == 0">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }}</div>
          <div class="content" v-else>分值: {{ item.score_all }}</div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }}</div>
          <div class="content" v-else>得分: {{ item.score }}</div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }}</div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }}</div>
          <div class="content">评估进度: {{ item.total_ratio }}</div>
        </template>
        <template v-if="role == 4 && coreData.inspect.type == 1 && coreData.inspect.examine_status>0">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }} / <span class="content-supv">{{ item.count }}</span></div>
          <div class="content" v-else>分值: {{ item.score_all }} / <span class="content-supv">{{ item.score_all }}</span></div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }} / <span class="content-supv">{{ item.examine_score }}</span></div>
          <div class="content" v-else>得分: {{ item.score }} / <span class="content-supv">{{ item.examine_score }}</span></div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }} / <span class="content-supv">{{ item.examine_score_ratio }}</span></div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }} / <span class="content-supv">{{ item.examine_score_ratio }}</span></div>
          <div class="content">评估进度: {{ item.total_ratio }} / <span class="content-supv">{{ item.examine_total_ratio }}</span></div>
        </template>
        <template v-if="role == 4 && coreData.inspect.type != 1 && coreData.inspect.examine_status>0">
          <div class="content" v-if="item.object_pid == 7">总项: {{ item.count }}</div>
          <div class="content" v-else>分值: {{ item.score_all }}</div>
          <div class="content" v-if="item.object_pid == 7">通过项: {{ item.score }}</div>
          <div class="content" v-else>得分: {{ item.score }}</div>
          <div class="content" v-if="item.object_pid == 7">通过比重: {{ item.score_ratio }}</div>
          <div class="content" v-else>得分比重: {{ item.score_ratio }}</div>
          <div class="content">评估进度: {{ item.total_ratio }}</div>
        </template>
      </div>
    </div>
    <div class="footer">北京京学科技发展集团有限公司提供技术支持</div>
  </div>
  <div class="btn-box" v-if="showBtn&&!(role==2&&coreData.inspect.examine_status == 0)">

    <!-- <el-button v-if="(role != 2 && role != 1) && questionnaire == null" class="feed-btn" @click="toFeedback(1)" type="primary" v-throttle>问卷调研
    </el-button> -->
    <el-button v-if="role == 3 && coreData.inspect.type == 2 || role == 6 &&  coreData.inspect.type == 4" class="feed-btn" @click="toFeedback(2)" :disabled="opinion.length != 0" type="primary" v-throttle>改进建议
    </el-button>
    <el-button v-if="(role == 1 || role == 2) && (coreData.inspect.type == 3 || coreData.inspect.type == 1)" class="feed-btn" @click="toFeedback(2)" :disabled="opinion.length != 0" type="primary" v-throttle>改进建议
    </el-button>
    <el-button class="login-btn" @click="addSupervisor" type="primary" v-throttle>提交
    </el-button>
  </div>
  <!-- <p class="btn-box" v-else @click="toList">返回</p> -->

</div>
<!-- </div> -->
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      isNew: '',
      role: '',
      pageType: '',
      inspect_id: '',
      showBtn: true,
      coreData: {
        list: [],
        inspect: {}
      },
      list: [],
      canSupervisor: true,
      fullscreenLoading: true,
      inspect_status: [],
      opinion: [],
      questionnaire: null
    };
  },
  computed: {
    isTest() {
      return location.href.indexOf('.test.') != -1 || location.href.indexOf(':808') != -1
    },
    isSelf() {
      return this.inspect_status.type == 3 && localStorage.getItem('role') == 2 || this.inspect_status.type == 2 && localStorage.getItem('role') == 3 || this.inspect_status.type == 1 && localStorage.getItem('role') == 4
    },
    showComplex() {
      return (this.role != 4 && this.inspect_status.type == 1 && this.inspect_status.submit_status == 1 && this.inspect_status.examine_status != 0)
    },
  },
  components: {},
  created() {

    this.role = localStorage.getItem('role')
    this.inspect_id = this.$route.query.id
    this.pageType = this.$route.query.type
    this.isNew = this.$route.query.is_new
    if (localStorage.getItem('role') == 4 && this.$route.query.type != 1) {
      this.showBtn = false
    } else if (localStorage.getItem('role') != 4 && this.$route.query.type == 3) {
      this.showBtn = false
    }
    this.init(this.$route.query.id)
  },
  methods: {
    async init(id) {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let res = await this.$api({
        method: 'get',
        url: '/api/object/list',
        data: {
          inspect_id: id
        }
      })
      loading.close();
      console.log(res)
      this.coreData = res.data
      this.inspect_status = res.data.inspect;
      this.opinion = res.data.opinion;
      this.questionnaire = res.data.questionnaire;
      console.log('角色：' + this.role + '评审类型' + res.data.inspect.type)
      // 账号只能评审同等级的  国家还可以评审机构的
      //4省级他评 2市级他评 3国家级督证 1机构自评
      // role: 2国家 6省 4机构 3市级 1超级管理员
      if(this.inspect_status.type == 1 && this.role != 4 && this.role != 2){
        this.showBtn = false
      }else if (this.inspect_status.type == 2 && this.role != 3) {
        this.showBtn = false
      }else if(this.inspect_status.type == 3 && this.role != 2){ 
        this.showBtn = false
      }else if(this.inspect_status.type == 4 && this.role != 6){ 
        this.showBtn = false
      }
      // this.list = res.data.list
    },
    addSupervisor() {
      
      if (this.isTest) {
        this.coreData.num = 0;
      }
      if (this.coreData.num > 0) {
        this.$Dialog.confirm({
            message: `有${this.coreData.num}项评估遗漏，无法提交。`,
            confirmButtonColor: '#0083F5',
            confirmButtonText: '确定',
          })
          .then(async () => {
            // this.showSubmit()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.showSubmit()
      }
    },
    showSubmit() {
      this.$Dialog.confirm({
        message: `提交后不可修改，确定提交吗？`,
        confirmButtonColor: '#0083F5',
        confirmButtonText: '提交',
      }).then(() => {
        this.$api({
          method: 'get',
          url: '/api/inspect/release',
          data: {
            inspect_id: this.inspect_id
          }
        }).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success('提交成功');
            this.toList()
            // sessionStorage.setItem('swiper', 1)
            // this.$router.go(-1);
          } else {
            this.$message.warning(res.msg)
            // this.toList()
          }
        })
      }).catch(() => {});
    },
    toList(){
      sessionStorage.setItem('swiper', this.role == 4 ? 1 : 2)
      if(this.isNew == 1){//新建
        this.$router.replace({
          path:'/main',
          query:{type: this.inspect_status.type}
        });
        return
      }
      this.$router.go(-1);
    },
    toFeedback(type) {
      this.$router.push({
        path: '/feedback',
        query: {
          id: this.inspect_id,
          type: type
        }
      })
    },
    toDetail(pid) {
      this.$router.push({
        path: '/detail',
        query: {
          id: this.inspect_id,
          pid: pid,
          type: this.pageType,
          is_new: this.isNew
        }
      })
    },
  },
};
</script>

<style lang="scss">
.container {
  height: 100%;

  .top-box--list {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 182px;
    // background-image: url('/img/main/background.png');
    // background-size: 100% 125px;

    .background {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 182px;
    }

    .title1 {
      margin: 28px 15px 15px;
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 18px;
    }

    .title2 {
      margin: 10px 0 0 15px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 14px;

      .title2-score {
        font-size: 18px;
        font-weight: 500;
        color: #FFF623;
        line-height: 18px;
      }
    }
  }

  .content-box--list {
    position: absolute;
    top: 169px;
    z-index: 99;
    height: calc(100% - 169px);
    width: 100%;
    overflow-y: scroll;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 5px 80px 5px;

    .single-box__outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      &::after {
        content: "";
        padding: 13px;
        margin-top: 10px;
        width: 167px;
        height: 157px;
        box-sizing: border-box;
      }

      .single-box {
        padding: 13px;
        margin-top: 10px;
        width: 167px;
        height: 157px;
        background: #FFFFFF;
        border-radius: 6px;

        .title {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 24px;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .content {
          text-align: left;
          margin-top: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 13px;

          .content-supv {
            color: #F73853
          }
        }
      }
    }

    .footer {
      position: relative;
      margin-top: 40px;
    }

  }

  .btn-box {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 73px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    .feed-btn {
      width: 323px;
      border: 1px solid #0083F5;
      background: #ffffff;
      font-size: 17px;
      font-weight: bold;
      color: #0083F5;
    }

    .el-button--primary.is-disabled,
    .el-button--primary.is-disabled:active,
    .el-button--primary.is-disabled:focus,
    .el-button--primary.is-disabled:hover {
      color: #FFF;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }

    .login-btn {
      width: 323px;
      font-size: 17px;
      color: #ffffff;
    }
  }

}
</style>
